import { Candidate, CandidateReport } from "@/dto/candidate/candidate";
import { useFetchStateStore } from "@/store/fetch-state";

export const useCandidateStore = defineStore("candidate", () => {
  const candidates = ref<Candidate[]>([]);
  const selectedCandidateId = ref<string | null>(null);
  const showProfile = ref(false);
  const fetchStateStore = useFetchStateStore();

  const selectedCandidate = computed<Candidate | undefined>(() =>
    candidates.value.find((candidate) => candidate.id === selectedCandidateId.value)
  );

  const getCandidates = async () => {
    await fetchStateStore.fetchOnce("candidates", async () => {
      candidates.value = await $api<Candidate[]>(API_ROUTES.candidate.index);
    });
  };

  const getCandidate = async (candidateId: string) => {
    const candidate = await $api<Candidate>(`${API_ROUTES.candidate.index}/${candidateId}`);
    if (candidate) addCandidateToStore(candidate);
    return candidate;
  };

  const reportCandidate = async (reportData: Partial<CandidateReport>) => {
    if (!reportData.profile_id) return;
    const report = await $api<CandidateReport>(API_ROUTES.candidate.report, { method: "post", body: reportData });
    if (report) {
      const candidate = candidates.value.find((candidate) => candidate.id === reportData.profile_id);
      if (candidate) candidate.reports.push(report);
    }
  };

  const addCandidateToStore = (candidate: Candidate) => {
    const index = candidates.value.findIndex((c: Candidate) => c.id === candidate.id);
    index !== -1 ? (candidates.value[index] = candidate) : candidates.value.unshift(candidate);
  };

  return {
    candidates,
    selectedCandidate,
    selectedCandidateId,
    getCandidates,
    getCandidate,
    showProfile,
    reportCandidate,
    addCandidateToStore,
  };
});
